import Home from 'app/pages/home/Home';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from 'app/components/Navbar'
import * as serviceWorker from '../serviceWorker';

//  Palette
//  #011627
//  #9A031E
//  #41EAD4
//  #FDFFFC
//  #8B94A3

const App: React.FC = () => {
    
    return (
        <div className='app-container' style={{display: 'flex', flexDirection: 'row'}}>
            <Router>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Home />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
