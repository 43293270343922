import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../static/3.png';
import './Navbar.scss'
const Navbar = () => {
    return (
        <>
        <nav className='navbar-container'>
            <div className='navbar-logo-container'>
                <Link className='navbar-logo' to="/">
                    <img src={Logo} style={{width: 'calc(5vw + 5vh)', height: 'calc(5vw + 5vh)'}}></img>
                    <span style={{color: 'white', fontSize: 'calc(1.1vw + 1.1vh)', fontFamily: "'Times New Roman', Times, serif"}}>Project Bet</span>
                </Link>
                <hr className='navbar-divider' style={{marginTop: '25px'}}></hr>
            </div>
            <div className='navbar-menu'>
                <NavLink to='/' className='navbar-menu-item'>
                    Home
                </NavLink>
                <NavLink to='/matches' className='navbar-menu-item'>
                    Matches
                </NavLink>
                <NavLink to='/simulation' className='navbar-menu-item'>
                    Simulation
                </NavLink>
                <NavLink to='/history' className='navbar-menu-item'>
                    History
                </NavLink>
                <NavLink to='/earnings' className='navbar-menu-item'>
                    Earnings
                </NavLink>
            </div>
            <div className='navbar-footer'>
                <hr className='navbar-divider'></hr>
                <NavLink to='/account' className='navbar-footer-menu-item' style={{marginTop: '10px', fontSize: 'calc(0.8vw + 0.8vh)'}}>
                    My Account
                </NavLink>
                <NavLink to='/' className='navbar-footer-menu-item' style={{fontSize: 'calc(0.8vw + 0.8vh)'}}>
                    Logout
                </NavLink>
            </div>
        </nav>
        <nav className='navbar-container-mobile'>

        </nav>
        </>
    );
};
export default Navbar;