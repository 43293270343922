import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../static/3.png'
import './LoginCard.scss'

const LoginCard = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(username, password);
    };

    return (
        <div className='login-container'>
            <div className='login-header'>
                <span className='login-header-title'>
                    Login
                </span>
            </div>
            <div className='login-body'>
                <form onSubmit={handleSubmit} className='login-form'>
                    <label htmlFor="username" className='login-form-label'>Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={handleUsernameChange}
                        className='login-form-field'
                    />
                    <label htmlFor="password" className='login-form-label'>Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className='login-form-field'
                    />
                    <button type="submit" className='login-form-button'>
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginCard;