import React from 'react';
import './Home.scss';
import LoginCard from '../../components/LoginCard';


const Home: React.FC = () => {
    return (
        <div className='home-container'>
            <div className='login-card'>
              <LoginCard />
            </div>
        </div>
    );
};

export default Home;
